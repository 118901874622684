
<template>
  <div class="report-view-container">
    <div class="title-container">
      <div class="back-icon" @click="closeView()"><i class="el-icon-back"></i>
        <span class="view-name">返回</span></div>
    </div>
    <div class="report-container">
      <img v-if="hasReport && study.reportFormat !== 'pdf'" class="report-img" :src="imageUrl" alt="无报告" @load="onLoad" @error="onError" />
      <VuePdf v-if="hasReport && study.reportFormat === 'pdf'" :src="pdfUrl" alt="无报告" @loaded="onLoad" @error="onError" />
      <div class="hint-container">
        <div class="hint-box">
          <Loading v-show="showLoading" />
          <div v-show="showHint">{{loadingHint}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '../Loading.vue'
import VuePdf from 'vue-pdf'

export default {
  name: 'ReportSnapshotView',
  components: {
    Loading,
    VuePdf
  },
  props: {
    study: {}
  },
  data() {
    return {
      imageUrl: '',
      pdfUrl: '',
      hasReport: true,
      showLoading: true,
      showHint: true,
      loadingHint: "正在加载报告"
    };
  },
  created() {
    this.updateUrls();
  },
  watch: {
    study: {
      handler() {
        this.updateUrls();
      },
      deep: true
    }
  },
  methods: {
    updateUrls() {
      if (this.study.reportFormat === 'pdf') {
        this.pdfUrl = `${this.study.pacsFasUrl}${this.study.rptStorageId}/getobject_inner_file.do?objectuid=${this.study.rptPath}&filename=report_1.pdf`;
      } else {
        this.imageUrl = `${this.study.pacsFasUrl}${this.study.rptStorageId}/getobject_inner_file.do?objectuid=${this.study.rptPath}&filename=report_1.jpg`;
      }
    },
    onLoad() {
      this.showLoading = false;
      this.showHint = false;
    },
    onError() {
      this.hasReport = false;
      this.showLoading = false;
      this.loadingHint = "显示报告失败";
    },
    closeView() {
      this.$emit('update:reportViewVisible', false);
    }
  }
}
</script>

<style scoped>.report-view-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #f6f6f6;
  overflow-y: auto;
  z-index: 4;
}
.title-container {
  height: 12vmin;
  display: flex;
  align-items: stretch;
}
.title-container .back-icon {
  font-size: 6vmin;
  padding: 0 3vmin;
  display: flex;
  align-items: center;
}
.title-container .view-name {
  font-size: 4vmin;
  margin-left: 3vmin;
}
.title-container .back-icon:active {
  background-color: #eaeaea;
}
.report-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 12vmin;
  bottom: 0;
  background-color: #f6f6f6;
  font-size: 4vmin;
  text-align: left;
  overflow: auto;
}
.report-img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.hint-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hint-container .hint-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>